<template>
  <div class="add-container">
    <div class="top-title">
      Add New Claim
    </div>
    <div class="add-cont">
      <a-form ref="formRef" :model="form" layout="vertical">
        <a-form-item field="reason" label="Reason">
          <a-radio-group v-model="form.reason">
            <a-radio v-for="val, k, i in reason" :key="k" :value="i + 1">{{ val }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item field="tracking" label="Tracking" :rules="[
          {
            required: true,
            message: 'Required'
          }
        ]">
          <a-input v-model="form.tracking" placeholder="E.g 0905202229387461" style="width:380px;" />
        </a-form-item>
        <a-card title="Upload Evidence" style="margin-top:24px;">
          <a-upload :action="adslink + '/api/merchant/uploadImage'" :headers="{
            Authorization: 'Bearer ' + token,
          }" multiple @success="uploadSuccess" @progress="onProgress" @change="onChange">
            <template #upload-button>
              <div class="upload-box">
                <div style="margin:20px;">
                  <icon-plus />
                </div>
                <div style="">
                  Click or drag the image here to Upload
                </div>
                <div style="color:var(--color-text-3 )">Up to 5 pictures can be uploaded, At least one is an invoice
                  picture.<br />
                  File name can only consist of letters and numbers.</div>
              </div>
            </template>
            <template #upload-item>
            </template>
          </a-upload>
          <div class="upload-item" v-if="file.status === 'uploading' && file.percent < 100">
            <div class="upload-item-cont">
              <a-space>
                <img class="upload-img" :src="file.url"
                  v-if="file.name.match(/[^.]+$/)[0] === 'png' || file.name.match(/[^.]+$/)[0] === 'jpeg' || file.name.match(/[^.]+$/)[0] === 'svg'">
                <icon-file v-else />
                <div>{{ file.name }}</div>
              </a-space>
              <div>
                <a-progress :percent="file.percent" type="circle" size="mini" class="progress" />
              </div>
            </div>
            <icon-delete @click="deleteImg(i)" class="deleteimg" />
          </div>
          <div class="upload-item" v-for="item, i in imglist" :key="i">
            <div class="upload-item-cont">
              <a-space>
                <img :src="item.url" alt=""
                  v-if="item.name.match(/[^.]+$/)[0] === 'png' || item.name.match(/[^.]+$/)[0] === 'jpeg' || item.name.match(/[^.]+$/)[0] === 'svg'" />
                <icon-file v-else />
                <div>{{ item.name }}</div>
              </a-space>
              <div>
                <a target="_blank" :href="item.url + '?' + Math.random">
                  <icon-eye />
                </a>
              </div>
            </div>
            <icon-delete @click="deleteImg(i)" class="deleteimg" />
          </div>
        </a-card>
        <div class="shipments-title">Product(s) For Claim </div>
        <div class="shipments-info">
          <div v-for="(item, index) in form.shipments" :key="index" id="package">
            <a-row :gutter="[20, 0]">
              <a-col :span="2" class="label-border">
                <a-form-item label="NO." :hide-label="index > 0">
                  <span class="ship-num">{{ index + 1 }}</span>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item label="Quantity" :hide-label="index > 0" :field="'shipments.' + index + '.q'" :rules="[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]">
                  <a-input-number v-model="item.q" :min="1" :max="200" placeholder="Required" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="Description" :hide-label="index > 0" :field="'shipments.' + index + '.d'" :rules="[{
                  maxLength: 35, minLength: 4, message: '4-35 characters.'
                }]">
                  <a-input v-model="item.d" placeholder="Optional 4-35 characters." />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="Unit Price" :hide-label="index > 0" :field="'shipments.' + index + '.p'"
                  :rules="unipriceRules.price">
                  <a-input v-model="item.p" placeholder="Required">
                    <template #prefix>
                      $
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-form-item label="Action" :hide-label="index > 0">
                  <a-button shape="circle" @click="deleteItem(item, index)" :disabled="form.shipments.length === 1">
                    <icon-delete />
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
          <a-popover position="left">
            <a-button shape="circle" @click="addPackage">
              <icon-plus />
            </a-button>
            <template #content>
              <div class="add-pop">Add Shipment</div>
              <a-input-number :style="{ width: '125px' }" placeholder="1~9" mode="button" :max="9" :min="1"
                v-model="addNums" @keydown.enter="addPackage" />
            </template>
          </a-popover>
        </div>
      </a-form>
    </div>
    <div class="addnew-footer">
      <div class="warn-tips">
        <div class="items">{{ totalQuantity }} items</div>
      </div>
      <a-space>
        <a-tag> Total Price</a-tag>
        <span class="charge"> ${{ totalPrice }}</span>
        <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
          @ok="reset" class="warnPop">
          <a-button shape="round">Reset</a-button>
        </a-popconfirm>
        <a-button shape="round" type="primary" @click="submit">Submit</a-button>
      </a-space>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from 'vue'
import local from '@/utils/local'
import { IconPlus, IconDelete, IconEye, IconFile } from '@arco-design/web-vue/es/icon'
import { toThousands } from '@/utils/handleNumber.js'
import { claimAdd } from '@/api/usercenter.js'
import { useRouter } from 'vue-router'
import { Notification } from '@arco-design/web-vue'
// 使用loading
import { load } from '@/utils/loading.js'
const adslink = process.env.VUE_APP_ADSLINK
const reason = JSON.parse(local.get('configInfo')).claim_reason
const token = local.get('token')
const formRef = ref(null)
const router = useRouter()
const form = reactive({
  reason: 1,
  tracking: '',
  shipments: [{
    q: 1,
    d: '',
    p: ''
  }]
})
const addNums = ref(1)
const addPackage = () => {
  if (addNums.value === '' || addNums.value === 1) {
    form.shipments.push({
      q: 1,
      d: '',
      p: ''
    })
  } else if (addNums.value > 1) {
    for (let i = 0; i < addNums.value; i++) {
      form.shipments.push({
        q: 1,
        d: '',
        p: ''
      })
    }
  }
}

function deleteItem (item, index) {
  // console.log(item)
  form.shipments.splice(index, 1)
}
const imglist = ref([])
const uploadSuccess = (fileItem) => {
  // console.log(fileItem)
  let res = JSON.parse(fileItem.response)
  if (res.code === 0) {
    imglist.value.unshift({ url: res.data.url, name: fileItem.name })
  } else {
    Notification.error({
      title: 'Error',
      content: res.message
    })
  }
  // console.log(imglist.value)
}
// 计算total quantity/price
const totalQuantity = ref(0)
const totalPrice = ref(0.00)
watch(() => form.shipments, (newVal) => {
  // console.log(newVal)
  let totalCount = 0
  let allPrice = 0
  newVal.forEach((item) => {
    totalCount += Number(item.q) || 0
    allPrice += Number(item.p) * Number(item.q) || 0
  })
  totalQuantity.value = totalCount
  totalPrice.value = toThousands(allPrice)
}, { immediate: true, deep: true })
// 重置
const reset = () => {
  formRef.value.resetFields()
  imglist.value = []
}
// 提交
const submit = () => {
  formRef.value.validate(async (value) => {
    if (!value) {
      if (imglist.value.length === 0) {
        Notification.error({
          title: 'Error',
          content: 'Please upload evidence.'
        })
        return
      }
      let imgs = []
      for (let i = 0; i < imglist.value.length; i++) {
        imgs.push(imglist.value[i].url)
      }
      load.show('Submitting...')
      const msg = await claimAdd({
        tracking_id: form.tracking,
        reason: form.reason,
        packages: form.shipments,
        note: '',
        images: imgs
      })
      // console.log(msg)
      if (msg.code === 0) {
        load.hide()
        Notification.success({
          title: 'Success',
          content: 'Claim submitted, please wait for review.'
        })
        router.push('/usercenter/claimlist')
      } else {
        load.hide()
        Notification.error({
          title: 'Error',
          content: msg.message
        })
      }
    }
  })
}
const deleteImg = (index) => {
  // console.log(index)
  imglist.value.splice(index, 1)
}
const file = ref({ status: '' })
const onChange = (_, currentFile) => {
  file.value = {
    ...currentFile
  }
}
const onProgress = (currentFile) => {
  file.value = currentFile;
}

const unipriceRules = {
  price: [{
    validator: (value, cd) => {
      console.log(value)
      let price = Number(value)
      if (price < 0 || price === 0) {
        cd(new Error('Should be greater than 0.').message)
      } else if (!price) {
        cd(new Error('Required').message)
      } else if (price > 1000000) {
        cd(new Error('Should be less than 100,0000.').message)
      }
    }
  }
  ]
}
</script>

<style lang="less" scoped>
.upload-item {
  display: flex;
  align-items: center;
  margin-top: 12px;

  .upload-item-cont {
    width: 100%;
    padding: 8px 10px 8px 12px;
    overflow: hidden;
    font-size: 14px;
    background-color: var(--color-fill-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .deleteimg {
    margin-left: 12px;
  }

  a {
    color: var(--color-text-2);
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.upload-img {
  width: 64px;
  height: 64px;
}

.upload-box {
  background-color: var(--color-fill-2);
  color: var(--color-text-1);
  border: 1px dashed var(--color-fill-4);
  height: 158px;
  width: 745px;
  border-radius: 2;
  line-height: 20px;
  text-align: center;
}

.shipments-info {
  padding-bottom: 110px;
}

.add-container {
  position: relative;
}

.top-title {
  padding: 18px 24px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
}

.add-cont {
  width: 780px;
  margin: 24px auto;
}

.shipments-title {
  margin-top: 24px;
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 29px;
}

#package :deep(.arco-form-item-label-col) {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border-2);
}

.ship-num {
  padding: 1px 8px;
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 14px;
  background: var(--color-fill-2);
  border-radius: 8px;
  line-height: 22px;
}

.add-pop {
  padding-bottom: 8px;
  color: var(--color-text-3);
  font-weight: 400;
  font-size: 12px;
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  padding: 20px 48px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
}

.items {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-1);
  margin-right: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charge {
  font-weight: 600;
  font-size: 24px;
  color: var(--color-text-1);
}

:deep(.arco-form-item-label-required-symbol) {
  display: none;
}

// :deep(.arco-upload-list-picture-mask .arco-upload-icon-upload) {
//   display: none;
// }
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}
</style>
